.banner {
    background: var(--primary-color-green);
    aspect-ratio: 9/4;
    overflow: hidden;
    @media(min-width: 768px){
        aspect-ratio: 16/5;
    }
    @media(min-width: 992px){
        aspect-ratio: 6/1;
    }
    .target_link {
        outline: none;
        display: block;
        text-align: center;
    }
}